import React from "react";
import { Redirect } from 'react-router-dom'

// reactstrap components
import {
    Container,
    Row,
    Col,
    TabPane,
} from "reactstrap";

// other imports
import * as Scroll from 'react-scroll';
import Microphone from 'sensorystream';
import 'assets/css/mycss.css'

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

let Element = Scroll.Element


class MyApp extends React.Component {
    constructor(props){
        super(props)
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
    }

    state = {
        redirect: false,
        pills: 2,
        setPills: 2,
    }

    handleTranscription(phrase){

        console.log(phrase);
        var command = null;
        if (phrase.match('home')!== null){
            command = "home";
        }
        else if (phrase.match('chili')!== null){
            console.log('Redireecting');
            this.setState({
                redirect: true
            }
        )
            return
        }
        else if (phrase.match('photos')!== null){
            command = "photos";
        }
        else if (phrase.match('recipe')!== null){
            command = "recipes";
        }
        else if (phrase.match('video')!== null){
            command = "video";
        }

        var scroller = Scroll.scroller;
        scroller.scrollTo(command, {
            duration: 1500,
            delay: 100,
            smooth: true,
        })

    }

    renderRedirect(){
        if (this.state.redirect) {
            return <Redirect to='/recipes?record=true' />
        }
    }

    render () {
        return (
        <>
            <Element name="home"></Element>
            {this.renderRedirect()}
            <ExamplesNavbar />
            <div className="wrapper">
                <LandingPageHeader />

                <div name="home" className="micStyles">
                    <Microphone
                        timestep={500}
                        audioFormat={'uint8'}
                        buttonSize={'5rem'}
                        socket={true}
                        animationSize={'7rem'}
                        micColor={'white'}
                        animationColor={'#84e09d'}
                        backgroundColor ={'#35d45f'}
                        endpoint={'https://openvoice.xvm.mit.edu/'}
                        transcriptCallback={(transcript) => this.handleTranscription(transcript)}
                        ranscriptParams={{language: 'en-US', interimResults: false, singleUtterance: false, model: null, speechContexts: ['VNS', 'wake', 'Brian', 'sigma', 'home', 'recipe']}}
                        rootServer='https://wake.mit.edu/vns/root'
                    />
                </div>
                <div className="section section-about-us">
                    <Element name="recipes"></Element>
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="title">Feeling Hungry?</h2>
                                <h5 className="description">
                                    The summer is a perfect time to hone yout culinary skills, as
                                    the markets are full of fresh and exciting ingredients. From
                                    refreshing salads, to amazing cold soups and juices, summer
                                    recipes are perfect for people on a run, as you can usually
                                    get them ready very fast.
                                </h5>
                            </Col>
                        </Row>
                        <div className="separator separator-primary"></div>

                    </Container>

                </div>
                <Row>
                    <TabPane tabId="pills1">
                        <Col className="ml-auto mr-auto" md="10">
                            <Row className="collections">
                                <Col md="6">
                                    <a href="/recipes">
                                        <h3 className="recipeStyle">1: Instant Pot Shakshuka</h3>
                                        <img
                                            alt="..."
                                            className="img-raised"
                                            src={require("assets/img/shakshuka1.jpg")}
                                        ></img>
                                    </a>
                                    <a href="/recipes">
                                        <h3 className="recipeStyle">3: Instant Pot Chili</h3>
                                        <img
                                            alt="..."
                                            className="img-raised"
                                            src={require("assets/img/chilli.jpg")}
                                        ></img>
                                    </a>
                                </Col>
                                <Col md="6">
                                    <a href="/recipes">
                                        <h3 className="recipeStyle">2: Instant Pot Chicken Noodle Soup</h3>
                                        <img
                                            alt="..."
                                            className="img-raised"
                                            src={require("assets/img/chickennoodle.jpg")}
                                        ></img>
                                    </a>
                                    <a href="/recipes">
                                        <h3 className="recipeStyle">4: Instant Pot Mac & Cheese</h3>
                                        <img
                                            alt="..."
                                            className="img-raised"
                                            src={require("assets/img/mac.jpg")}
                                        ></img>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </TabPane>
                </Row>

                <DefaultFooter />
            </div>
        </>
                                                                                );
                                                                            }
                                                                            }

                                                                            export default MyApp;
