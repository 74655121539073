import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
    NavItem,
    Nav,
    NavLink,
    Card,
    CardHeader,
    CardBody,
    TabContent,
    TabPane,
    Carousel,
    CarouselItem,
    CarouselIndicators
} from "reactstrap";

// other imports
import * as Scroll from 'react-scroll';
import Microphone from 'sensorystream';
import { Redirect } from 'react-router-dom'


// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import RecipeHeader from "components/Headers/RecipeHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

let Element = Scroll.Element

class Recipe extends React.Component {

    constructor(props) {
        super(props)
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
    }


    state = {
            plainTabs: "1",
            redirect: false,
            items: [
                {
                    altText: "1 tbsp. extra-virgin olive oil",
                    caption: "1 tbsp. extra-virgin olive oil",
                    src: require("assets/img/oil.jpg"),
                },
                {
                    altText: "1 onion, chopped",
                    caption: "1 onion, chopped",
                    src: require("assets/img/onion.jpg"),
                },
                {
                    src: require("assets/img/garlic.jpeg"),
                    altText: "3 cloves garlic, minced",
                    caption: "3 cloves garlic, minced"
                },
                {
                    altText: "2 tbsp. tomato paste",
                    caption: "2 tbsp. tomato paste",
                    src: require("assets/img/paste.jpg"),
                },
                {
                    altText: "1 1/2 lb. ground beef",
                    caption: "1 1/2 lb. ground beef",
                    src: require("assets/img/beef.jpg"),
                },
                {
                    altText: "2 c. low-sodium beef broth",
                    caption: "2 c. low-sodium beef broth",
                    src: require("assets/img/broth.jpg"),
                },
                {
                    altText: "1 (15-oz.) can kidney beans, rinsed and drained",
                    caption: "1 (15-oz.) can kidney beans, rinsed and drained",
                    src: require("assets/img/kidney.jpg"),
                },
                {
                    altText: "1 (15-oz.) can black beans, rinsed and drained",
                    caption: "1 (15-oz.) can black beans, rinsed and drained",
                    src: require("assets/img/beans.jpg"),
                },
                {
                    altText: "1 (15-oz.) can fire-roasted diced tomatoes",
                    caption: "1 (15-oz.) can fire-roasted diced tomatoes",
                    src: require("assets/img/tomato.jpg"),
                },
                {
                    altText: "1 1/2 tbsp.  chili powder",
                    caption: "1 1/2 tbsp.  chili powder",
                    src: require("assets/img/chili_powder.jpg"),
                },
            ],
            activeIndex: 0,
            animating: false
        }

    setPlainTabs = (num) => {
        this.setState({
            plainTabs: num
        }
        )
    }

    nextStep(){
        let step = parseInt(this.state.plainTabs, 10)
        console.log(step);
        if (step<5){
            step += 1
        }
        this.changeStep(step)
    }

    previousStep(){
        let step = parseInt(this.state.plainTabs, 10)
        console.log(step);
        if (step>1){
            step -= 1
        }
        this.changeStep(step)
    }

    changeStep(num) {
        this.setState({
            plainTabs: num.toString()
        })
    }

    renderRedirect(){
        if (this.state.redirect) {
            return <Redirect to='/?record=true' />
        }
    }

    handleTranscription(phrase){
        console.log(phrase);
        phrase = phrase.toLowerCase()
        var command = null;
        if (phrase.match('home')!== null){
            command = "home";
        }
        else if (phrase.match('introduction')!== null){
            command = "introduction";
        }
        else if (phrase.match('ingredients')!== null){
            command = "ingredients";
        }
        else if (phrase.match('back')!== null){
            this.setState({
                redirect: true
            }
        )
        }
        else if (phrase.match('recipe')!== null){
            command = "steps";
        }
        else if (phrase.match('next step')!== null){
            this.nextStep()
        }
        else if (phrase.match('previous step')!== null){
            this.previousStep()
        }
        else if (phrase.match('next ingredient')!== null){
            this.nextIngredient()
        }
        else if (phrase.match('previous ingredient')!== null){
            this.previousIngredient()
        }
        else if (phrase.match('1')!== null || phrase.match('one')!== null){
            this.changeStep(1)
        }
        else if (phrase.match('2')!== null || phrase.match('two')!== null){
            this.changeStep(2)
        }
        else if (phrase.match('3')!== null || phrase.match('three')!== null){
            this.changeStep(3)
        }
        else if (phrase.match('4')!== null || phrase.match('four')!== null){
            this.changeStep(4)
        }
        else if (phrase.match('5')!== null || phrase.match('five')!== null){
            this.changeStep(5)
        }

        var scroller = Scroll.scroller;
        scroller.scrollTo(command, {
            duration: 1500,
            delay: 100,
            smooth: true,
        })

    }

    onExiting = () => {
        this.setState({
            animating: true
        }
        )
    }

    onExited = () => {
        this.setState({
            animating: false
        }
        )
    }

    nextIngredient = () => {
        if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({
            activeIndex: nextIndex
        }
        );
  };

  previousIngredient = () => {
      if (this.state.animating) return;
      const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
      this.setState({
          activeIndex: nextIndex
      }
      );
};

    goToIndex = (newIndex) => {
        if (this.state.animating) return;
        this.setState({
            activeIndex: newIndex
        }
        );
    };

    render() {
    return (
        <>
            <ExamplesNavbar />
            <Element name="home"></Element>
            {this.renderRedirect()}
            <div className="wrapper">
                <RecipeHeader />

                <div name="home" className="micStyles">
                    <Microphone
                        timestep={500}
                        audioFormat={'uint8'}
                        buttonSize={'5rem'}
                        socket={true}
                        animationSize={'7rem'}
                        micColor={'white'}
                        animationColor={'#84e09d'}
                        backgroundColor ={'#35d45f'}
                        endpoint={'https://openvoice.xvm.mit.edu/'}
                        transcriptCallback={(transcript) => this.handleTranscription(transcript)}
                        ranscriptParams={{language: 'en-US', interimResults: false, singleUtterance: false, model: null, speechContexts: ['VNS', 'wake', 'Brian', 'sigma', 'home', 'recipe']}}
                        rootServer='https://wake.mit.edu/vns/root'
                    />
                </div>

                <Element name="introduction"></Element>
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h2 className="title">It's Chili time!</h2>
                            <h5 className="description">
                                If there's one thing the Instant Pot does well,
                                it's chili. The pot takes away all of the legwork of
                                standing and stirring over the stove for forever.
                                This is now our favorite chili recipe. It's so flavorful!
                                Swap in different beans or use ground turkey instead.
                                It will all come out delicious!

                                If you try this recipe, don't forget to rate it
                                and let us know in the comments!
                            </h5>
                        </Col>
                    </Row>
                    <div className="separator separator-primary"></div>

                </Container>



                <div className="section section-about-us">
                    <Row>
                        <Element name="ingredients"></Element>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h2 className="title">Ingredients</h2>
                        </Col>

                        <div className="carrousel">

                            <Carousel activeIndex={this.state.activeIndex} slide={false} interval={100000} next={this.nextIngredient} previous={this.previousIngredient}>
                                <CarouselIndicators
                                    items={this.state.items}
                                    activeIndex={this.state.activeIndex}
                                    onClickHandler={this.goToIndex}
                                />
                                {this.state.items.map(item => {
                                    return (
                                        <CarouselItem
                                            onExiting={this.onExiting}
                                            onExited={this.onExited}
                                            key={item.altText}
                                        >
                                            <img src={item.src} alt={item.altText} className="ingredientImage"/>
                                            <div className="carousel-caption d-none d-md-block">
                                                <h4 className="caption">{item.caption}</h4>
                                            </div>
                                        </CarouselItem>
                                    );
                                })}
                                <a
                                    className="carousel-control-prev"
                                    data-slide="prev"
                                    href="#pablo"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.previousIngredient();
                                    }}
                                    role="button"
                                >
                                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                                </a>
                                <a
                                    className="carousel-control-next"
                                    data-slide="next"
                                    href="#pablo"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.nextIngredient();
                                    }}
                                    role="button"
                                >
                                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                                </a>
                            </Carousel>
                        </div>
                    </Row>
                </div>
                <div className="recipeSteps">

                    <Container>
                        <Row>
                            <Element name="steps"></Element>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="title">Recipe Steps</h2>
                            </Col>
                        </Row>
                    </Container>
                    <div className="cardStyle">
                        <Card className="card-nav-tabs card-plain">
                            <CardHeader className="card-header-danger">
                                <div className="nav-tabs-navigation">
                                    <div className="nav-tabs-wrapper">
                                        <Nav data-tabs="tabs" tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={this.state.plainTabs === "1" ? "active" : ""}
                                                    href="#pablo"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.changeStep("1");
                                                    }}
                                                >
                                                    Step 1
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={this.state.plainTabs === "2" ? "active" : ""}
                                                    href="#pablo"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.changeStep("2");
                                                    }}
                                                >
                                                    Step 2
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={this.state.plainTabs === "3" ? "active" : ""}
                                                    href="#pablo"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.changeStep("3");
                                                    }}
                                                >
                                                    Step 3
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={this.state.plainTabs === "4" ? "active" : ""}
                                                    href="#pablo"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.changeStep("4");
                                                    }}
                                                >
                                                    Step 4
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={this.state.plainTabs === "5" ? "active" : ""}
                                                    href="#pablo"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.changeStep("5");
                                                    }}
                                                >
                                                    Step 5
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <TabContent
                                    className="text-center"
                                    activeTab={"plainTabs" + this.state.plainTabs}
                                >
                                    <TabPane tabId="plainTabs1">
                                        <h4>
                                            Set Instant Pot to Sauté function and add oil.
                                            Add onion and cook until soft, 5 minutes.
                                        </h4>
                                    </TabPane>
                                    <TabPane tabId="plainTabs2">
                                        <h4>
                                            Stir in garlic and cook until fragrant, 1 minute,
                                            then add tomato paste, stirring to combine.
                                        </h4>
                                    </TabPane>
                                    <TabPane tabId="plainTabs3">
                                        <h4>
                                            Add ground beef and cook, breaking meat up with a
                                            wooden spoon, until no longer pink, 7 minutes. Drain
                                            fat, if necessary.
                                        </h4>
                                    </TabPane>
                                    <TabPane tabId="plainTabs4">
                                        <h4>
                                            Return insert to Instant Pot and add broth, beans, tomatoes,
                                            chili powder, cumin, oregano and cayenne. Season with salt
                                            and pepper. Lock lid and set to Pressure Cook on High for
                                            14 minutes.
                                        </h4>
                                    </TabPane>
                                    <TabPane tabId="plainTabs5">
                                        <h4>
                                            Follow manufacturer’s instructions to quick release,
                                            then remove lid. Serve with desired toppings.
                                        </h4>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>

                    </div>
                </div>



                <DefaultFooter />
            </div>
        </>
                                                                                );
                                                                            }
                                                                            }

                                                                            export default Recipe;
