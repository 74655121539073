"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hideLoader = exports.showLoader = exports.animationContainer = exports.icon = exports.iconWrapper = exports.micContainer = exports.micWrapper = exports.centerFlex = void 0;

var _core = require("@emotion/core");

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n\ttransition: opacity 0.5s;\n\topacity: 0;\n\tpointer-events: none;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n\tposition: relative;\n\tz-index: 10;\n\ttransition: opacity 0.5s;\n\topacity: 1;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var centerFlex = {
  //div
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};
exports.centerFlex = centerFlex;

var micWrapper = _objectSpread({}, centerFlex, {
  flexDirection: 'column'
});

exports.micWrapper = micWrapper;

var micContainer = _objectSpread({}, centerFlex, {
  position: 'relative',
  borderRadius: '50%',
  cursor: 'pointer',
  zIndex: '15'
});

exports.micContainer = micContainer;

var iconWrapper = _objectSpread({}, centerFlex);

exports.iconWrapper = iconWrapper;
var icon = {
  height: '100%',
  width: 'auto'
};
exports.icon = icon;

var animationContainer = _objectSpread({}, micWrapper, {
  position: 'absolute',
  zIndex: '10'
});

exports.animationContainer = animationContainer;
var showLoader = (0, _core.css)(_templateObject());
exports.showLoader = showLoader;
var hideLoader = (0, _core.css)(_templateObject2());
exports.hideLoader = hideLoader;